import {z} from 'zod';
import {NB_ANNEES_SIMU} from '~/legacy/utils/constantes';

export const propertyTypeSchema = z.enum(['T1', 'T2', 'T3', 'T4', 'T5', 'MI', 'CM', 'LC', 'B', 'S', 'LA', 'multilot']);
export type PropertyType = z.infer<typeof propertyTypeSchema>;

export const propertyTypeLabels: {[key in PropertyType]: string} = {
  T1: 'T1',
  T2: 'T2',
  T3: 'T3',
  T4: 'T4',
  T5: 'T5',
  MI: 'Maison Individuelle',
  CM: 'Chambre Médicalisée',
  LC: 'Locaux Commerciaux',
  B: 'Bureaux',
  LA: "Local d'activité",
  S: 'Studio',
  multilot: 'Multi-lot',
};
export const datedStateAmountFromPropertyType: {[key in PropertyType]: number} = {
  T1: 380,
  T2: 380,
  T3: 380,
  T4: 380,
  T5: 380,
  MI: 0,
  CM: 0,
  LC: 0,
  B: 0,
  LA: 0,
  S: 0,
  multilot: 380,
};
export const defaultWorkingCapitalProvisionYear6FromPropertyType: {[key in PropertyType]: number} = {
  T1: 50,
  T2: 70,
  T3: 90,
  T4: 110,
  T5: 130,
  MI: 0,
  CM: 0,
  LC: 0,
  B: 0,
  LA: 0,
  S: 50,
  multilot: 0,
};

export const propertyTypeOptions: {label: string; value: PropertyType}[] = [
  {label: propertyTypeLabels.CM, value: 'CM'},
  {label: propertyTypeLabels.S, value: 'S'},
  {label: propertyTypeLabels.T1, value: 'T1'},
  {label: propertyTypeLabels.T2, value: 'T2'},
  {label: propertyTypeLabels.T3, value: 'T3'},
  {label: propertyTypeLabels.T4, value: 'T4'},
  {label: propertyTypeLabels.T5, value: 'T5'},
  {label: propertyTypeLabels.MI, value: 'MI'},
  {label: propertyTypeLabels.LC, value: 'LC'},
  {label: propertyTypeLabels.B, value: 'B'},
  {label: propertyTypeLabels.LA, value: 'LA'},
];

export const calculeEtatDate = ({propertyType}: {propertyType: PropertyType}): number[] => {
  const _etat_date = Array(NB_ANNEES_SIMU).fill(-datedStateAmountFromPropertyType[propertyType]);
  return _etat_date;
};
