import {z} from 'zod';
import {filterNonNull} from '~/utils/filter-non-null';

export const optionalfieldNumberSchema = z
  .union([
    z.number(),
    z.string().transform(n => {
      const num = Number(n);
      if (isNaN(num)) {
        return undefined;
      }
      return num;
    }),
  ])
  .optional();
export const requiredfieldNumberSchema = z.union([
  z.number(),
  z.string().transform((n, ctx) => {
    const num = Number(n);
    if (isNaN(num)) {
      throw new Error(`Invalid number ${ctx.path}`);
    }
    return num;
  }),
]);

export const idSchema = z.union([z.number(), z.string()]).transform(Number);

export const trueSchema = z.union([z.enum(['on', 'true']), z.literal(true)]).transform(n => true);
export const falseSchema = z.union([z.enum(['off', 'false']), z.literal(false), z.undefined()]).transform(n => false);
export const formBooleanSchema = z.union([trueSchema, falseSchema]);

export const formNumberSchema = z.union([
  z.number(),
  z.string().transform(a => (a.length <= 0 || isNaN(Number(a)) ? undefined : Number(a))),
]);
export const formRequiredNumberSchema = z.union([
  z.number(),
  z.string().transform(a => (a.length <= 0 || isNaN(Number(a)) ? 0 : Number(a))),
]);
export const idListSchema = z.array(optionalfieldNumberSchema).transform(list => {
  return filterNonNull(list);
});
export const orientationSchema = z.enum(['N', 'NO', 'NE', 'S', 'SO', 'SE', 'E', 'O']);

export const requiredFormDateSchema = z.string().transform(n => {
  const date = new Date(n);
  if (isNaN(date.getTime())) {
    return new Date(500, 0, 1, 12);
  }
  return date;
});
export const formDateSchema = z.string().transform(n => {
  const date = new Date(n);
  if (isNaN(date.getTime())) {
    return undefined;
  }
  return date;
});

export const docSchema = z.object({
  added: z
    .array(
      z.object({
        name: z.string(),
        base64: z.string(),
      }),
    )
    .optional(),
  removed: z.array(z.string()).optional(),
});

export type DocData = z.infer<typeof docSchema>;
