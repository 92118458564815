import {CCEtudeDetaillee} from '~/legacy/common/types/main';

import {FiscalType} from '@prisma/client';
import {FiscalZone, fiscalConfigSchema, fiscalConfigService} from '~/domains/property';
import {PropertyType, propertyTypeLabels} from '~/domains/property/property-type';
import * as Const from './constantes';

export const arrondi = (x: number, n = 5): number => {
  return parseFloat(x.toFixed(n));
};

export const getTVAFromTTC = (prixTTC: number): number => {
  return (prixTTC / (1 + Const.TAUX_TVA)) * Const.TAUX_TVA;
};

export const getHTFromTTC = (prixTTC: number): number => {
  return prixTTC / (1 + Const.TAUX_TVA);
};

export const getTTCFromHT = (prixHT: number): number => {
  return prixHT * (1 + Const.TAUX_TVA);
};

export const tableauAmortissement = (montant: number, duree: number, decalage: number): number[] => {
  const retTab = Array(Const.NB_ANNEES_SIMU).fill(0);
  if (duree !== 0) {
    const amort_annuel = arrondi(montant / duree);
    retTab.fill(amort_annuel, decalage, Math.min(duree + decalage - 1, Const.NB_ANNEES_SIMU));
    if (duree + decalage <= Const.NB_ANNEES_SIMU) {
      retTab[duree + decalage - 1] = arrondi(montant - amort_annuel * (duree - 1));
    }
  }
  return retTab;
};

interface CumulTableauProps {
  tableau: number[];
  moyenne?: boolean;
  douziemeAnneeSuivante?: number; // number from 0 to 11
}
export const cumulTableau = ({moyenne = false, tableau, douziemeAnneeSuivante = 0}: CumulTableauProps): number[] => {
  const ret = [];

  let accumulator = 0;
  for (let i = 0; i < tableau.length; i += 1) {
    accumulator = accumulator + tableau[i];
    let rollingValue = (tableau[Math.min(i + 1, tableau.length - 1)] * douziemeAnneeSuivante) / 12;
    const rollingAccumulator = accumulator + rollingValue;

    ret.push(arrondi(moyenne ? rollingAccumulator / (i + 1) : rollingAccumulator));
  }
  return ret;
};

export const isChecked = (globalValue: number, checkboxValue: number | string): boolean => {
  const _cBV = parseInt(checkboxValue.toString());
  if (globalValue === -1) return false;
  const a = Math.trunc(globalValue / _cBV);
  const b = Math.trunc(globalValue / (_cBV * 2));
  const ret = a - b * 2 === 1 ? true : false;
  return ret;
};

/**
 * Crée un tableau de NB_ANNEES_SIMU valeurs revalorisées selon le coefficient CM
 * @param tab Tableau de base (si par exemple reval
 * à partir de la 11ème valeur - cf indexDep - en gardant les précédentes)
 * @param value valeur de départ
 * @param indexDep premier index auquel appliquer la revalorisation
 * @param CM coefficient multiplicateur de revalorisation
 * @param nbCA arrondi (nombre de chiffres après la virgule)
 * @returns
 */
export const getTableauReval = (
  tab: number[] | null,
  value: number,
  indexDep: number,
  CM: number,
  nbCA: number,
  shouldLog = false,
): number[] => {
  const retValue = tab === null ? Array(Const.NB_ANNEES_SIMU).fill(0) : [...tab];
  for (let i = indexDep; i < retValue.length; i += 1) {
    retValue[i] = arrondi(value, nbCA);
    value = value * CM;
  }

  return retValue;
};

export const getMonthlyPinelAutoRent = ({
  fiscalType,
  surface,
  pinelZone,
}: {
  fiscalType: FiscalType;
  surface: number;
  pinelZone?: FiscalZone;
}): number => {
  const fiscalConfig = fiscalConfigSchema.parse({type: fiscalType});
  if (fiscalConfigService.is.pinel(fiscalConfig)) {
    if (pinelZone === Const.PINEL_ZONE_A) {
      return arrondi(surface * Const.PINEL_ZONE_A_VAL);
    }
    if (pinelZone === Const.PINEL_ZONE_A_BIS) {
      return arrondi(surface * Const.PINEL_ZONE_A_BIS_VAL);
    }
    if (pinelZone === Const.PINEL_ZONE_B1) {
      return arrondi(surface * Const.PINEL_ZONE_B1_VAL);
    }
    if (pinelZone === Const.PINEL_ZONE_B2_C) {
      return arrondi(surface * Const.PINEL_ZONE_B2_C_VAL);
    }
  }
  return 0;
};

export const sommeTab = (tabNumber: number[], indexDebut = 0, indexFin = tabNumber.length - 1): number => {
  const _indexDebut = indexDebut < 0 ? 0 : indexDebut;
  const _indexFin = indexFin > tabNumber.length - 1 ? tabNumber.length - 1 : indexFin;
  let somme = 0;
  for (let i = _indexDebut; i <= _indexFin; i += 1) {
    somme += tabNumber[i];
  }
  return somme;
};

export const premiereAnneeBenefices = ({resultat_imposable}: {resultat_imposable: number[]}): number => {
  for (let i = 0; i < resultat_imposable.length; i += 1) {
    if (resultat_imposable[i] >= 0) {
      return i + 1;
    }
  }
  return resultat_imposable.length;
};

interface ReferenceProps {
  propertyType: PropertyType;
  propertyName: string;
  programName: string;
  city: string;
}
export const getReferenceProperty = (property: ReferenceProps, versionCourte = false): string => {
  const {propertyType, propertyName, programName, city} = property;
  let texteTypeLot = propertyTypeLabels[propertyType];

  if ((propertyType as string) === '' && propertyName === '' && programName === '' && city === '') {
    return '';
  } else {
    const texteFinalNomProgramme = versionCourte ? '' : programName !== '' ? `"${programName}" ` : '';
    const texteFinalTypeLot = texteTypeLot !== '' ? `${versionCourte ? '' : ' '}${texteTypeLot}` : '';
    const texteFinalNumeroLot = versionCourte ? '' : propertyName !== '' ? ` n°${propertyName}` : '';
    const texteFinalVille = city !== '' ? ` à ${city}` : '';

    return `${
      propertyType === 'multilot'
        ? `multilot`
        : `${texteFinalNomProgramme}${versionCourte ? '' : 'lot'}${texteFinalTypeLot}`
    }${texteFinalNumeroLot}${texteFinalVille}`;
  }
};

export const getReferenceFromProperties = (
  properties: ReferenceProps[],
  indexLot: number,
  versionCourte = false,
): string => {
  if (indexLot >= properties.length || properties.length === 0) {
    return '';
  } else if (indexLot === -1 && properties.length > 1) {
    return `multi-lot`;
  }
  const property =
    indexLot === -1
      ? properties.length === 1
        ? properties[0]
        : ({programName: '', city: '', propertyName: '', propertyType: 'T1'} as ReferenceProps) // au cas où, mais n'arrive pas vu les conditions ci-dessus
      : properties[indexLot];
  return getReferenceProperty(property, versionCourte);
};

export const calculMFAE = (
  montantAEmprunter: number,
  autresCommissionsCourtages: number,
  _PGB: number,
  _EFFPC: number,
  financialFeeBorrowed: boolean,
): number => {
  return arrondi(montantAEmprunter + autresCommissionsCourtages + _PGB + (financialFeeBorrowed ? _EFFPC : 0));
};

export const getContraintesMdpNonRespectees = (pwd: string): string[] => {
  const ret: string[] = [];
  if (!pwd.match(/[0-9]/g)) {
    ret.push(`contenir un chiffre`);
  }
  if (!pwd.match(/[A-Z]/g)) {
    ret.push(`contenir une lettre majuscule`);
  }
  if (!pwd.match(/[a-z]/g)) {
    ret.push(`contenir une lettre minuscule`);
  }
  if (!pwd.match(/[^a-zA-Z\d]/g)) {
    ret.push(`contenir un caractère spécial`);
  }
  if (pwd.length < 8) {
    ret.push(`contenir au moins 8 caractères`);
  }
  return ret;
};

export const getIndexReversePremierAPartirDuquelNul = (tabNumber: number[]): number => {
  for (let i = tabNumber.length - 1; i >= 0; i -= 1) {
    if (tabNumber[i] !== 0) return i + 1;
  }
  return 0;
};

export const sommeCCED = (eDS: CCEtudeDetaillee[], key: keyof CCEtudeDetaillee): number[] => {
  const ret: number[] = [];
  for (let annee = 0; annee < Const.NB_ANNEES_SIMU; annee += 1) {
    let _somme = 0;
    for (let i = 0; i < eDS.length; i += 1) {
      _somme += eDS[i][key][annee] as number;
    }
    ret.push(_somme);
  }
  return ret;
};

export const getSiTousPareils = <R>(tabATester: R[], valSiJamaisTousPareils: R): R => {
  if (tabATester.length === 0) {
    return valSiJamaisTousPareils;
  }
  const premiereVal = tabATester[0];
  let tousPareils = true;
  let j = 1;
  while (tousPareils && j < tabATester.length) {
    if (tabATester[j] !== premiereVal) {
      tousPareils = false;
    }
    j += 1;
  }
  if (tousPareils) {
    return premiereVal;
  }
  return valSiJamaisTousPareils;
};

export const getFurnishingYearIndexFromProperties = (
  infosLots: {furnishingYearIndex?: number}[],
): number | 'divers' | undefined => {
  const furnishingYearIndexes = infosLots.map(el => el.furnishingYearIndex);
  if (furnishingYearIndexes.length === 1) {
    return furnishingYearIndexes[0];
  }
  const furnishingYearIndexesUniques = [...new Set(furnishingYearIndexes)];
  if (furnishingYearIndexesUniques.length === 1) {
    return furnishingYearIndexesUniques[0];
  } else {
    return 'divers';
  }
};

export const getTaxReductionFromProperties = (props: {taxReductionRate?: number}[]) => {
  const CBRIs = props.map(el => el.taxReductionRate);
  const CBRIUniques = [...new Set(CBRIs)];
  if (CBRIUniques.length === 1) {
    return CBRIUniques[0];
  } else {
    return 'divers';
  }
};

export const decaleTableauDernierRepete = (tab: number[], decalage: number): number[] => {
  const long = tab.length;
  const ret = [...tab];
  return ret.slice(decalage, long).concat(Array(decalage).fill(tab[long - 1]));
};
export const decaleTableauPremiers0 = (tab: (string | number)[], decalage: number): number[] => {
  const long = tab.length;
  const ret = [...tab];
  return Array(decalage)
    .fill(0)
    .concat(ret.slice(0, long - decalage));
};

export const getNiceFirmName = (nomSociete: string, majusculesVaerdi = false): string => {
  const joliNom = nomSociete.toLowerCase() === 'vaerdi' ? (majusculesVaerdi ? 'VÆRDI' : 'Værdi') : nomSociete;
  return joliNom;
};

export const hash = (s: string): number => {
  var hash = 0,
    i,
    chr;
  if (s.length === 0) return hash;
  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};
