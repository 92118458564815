import {ComboboxOption} from '~/components/ui/combo-box';

export const monthes = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];

export const monthesOptions: ComboboxOption[] = monthes.map((mois, index) => ({
  value: (index + 1).toString(),
  label: mois.toLowerCase(),
}));

export const getDateFromStringInput = (dateString?: string) => {
  return dateString && typeof new Date(dateString).getTime === 'function' ? new Date(dateString) : null;
};

export const getYearFromDate = (date: Date | null | undefined, defaut = 500) => {
  return date?.getFullYear() || defaut;
};

/**
 * Calcule la différence entre une date et une deuxième (si deuxième pas précisée : maintenant)
 * @param date1 date 1
 * @param date2 date 2 facultative (si pas précisée : maintenant)
 * @returns  nombre de mois de différence, arrondi par défaut
 */
export const monthDateDiff = (date1: Date, date2 = new Date()) => {
  const yearDiff = new Date(date2).getFullYear() - new Date(date1).getFullYear();
  const monthDiff = new Date(date2).getMonth() - new Date(date1).getMonth();
  return yearDiff * 12 + monthDiff;
};

export const getYearMonthDurationFr = (durationInMonths: number) => {
  const years = Math.floor(durationInMonths / 12);
  const months = Math.floor(durationInMonths - 12 * years);
  if (years === 0) {
    return `${months} mois`;
  }
  const monthPart = months === 0 ? '' : ` ${months} mois`;

  if (years === 1) {
    return `1 an${monthPart}`;
  }
  return `${years} ans${monthPart}`;
};
