import {SerializeFrom} from '@remix-run/node';
import {Pret, PretSansRenegos} from '~/legacy/common/types/main';
import {monthDateDiff} from '../../services/dates';
import {arrondi} from './utils';

const defaultDateKeyList = ['loanStartDate', 'firstRentDate', 'deliveryDate', 'authenticActDate', 'anticipatedEndDate'];
interface JSONParserWithDatesProps {
  stringifedPrets: string;
  dateKeyList?: string[];
}
export const JSONParserWithDates = ({stringifedPrets, dateKeyList = defaultDateKeyList}: JSONParserWithDatesProps) => {
  const dateTimeReviver = (key: string, value: any) => {
    if (dateKeyList.includes(key)) {
      return new Date(value);
    }
    return value;
  };
  try {
    return JSON.parse(stringifedPrets, dateTimeReviver);
  } catch (e) {
    console.log(e);
  }
};

export const additionnePrets = (prets: Pret[], fn: (pret: Pret) => number, inclureRenegos = true): number => {
  let ret = 0;
  for (let i = 0; i < prets.length; i += 1) {
    ret += fn(prets[i]);
    if (inclureRenegos && prets[i].renegos !== undefined) {
      for (let j = 0; j < prets[i].renegos.length; j += 1) {
        ret += additionnePrets(prets[i].renegos, fn);
      }
    }
  }
  return ret;
};

export const containsRenego = (prets: SerializeFrom<Pret>[]): boolean => {
  for (let i = 0; i < prets.length; i += 1) {
    if (prets[i].renegos !== undefined && prets[i].renegos.length > 0) {
      return true;
    }
  }
  return false;
};

export const getPretSansRenegosFromPret = (pB: Pret): PretSansRenegos => {
  const pretSansRenegos: any = {
    ...pB,
  };
  delete pretSansRenegos.renegos;
  return pretSansRenegos;
};

interface GetRealLoanDurationProps {
  loanStartDate?: Date;
  renegoStartDate?: Date;
  loanDuration: number;
}

export const getRealLoanDuration = ({loanStartDate, renegoStartDate, loanDuration}: GetRealLoanDurationProps) => {
  if (
    loanStartDate &&
    renegoStartDate &&
    Math.abs(loanStartDate.getFullYear() - new Date(renegoStartDate).getFullYear()) < 100
  ) {
    return Math.max(0, monthDateDiff(loanStartDate, renegoStartDate));
  }
  return loanDuration;
};

export const getFinalAmountToBorrow = ({
  amountToBorrow,
  loanFees,
  PGB,
  EFFPC,
  financialFeeBorrowed,
}: {
  amountToBorrow: number;
  loanFees: number;
  PGB: number;
  EFFPC: number;
  financialFeeBorrowed: boolean;
}): number => {
  return arrondi(amountToBorrow + loanFees + PGB + (financialFeeBorrowed ? EFFPC : 0));
};
